var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row books"
  }, [_c('div', {
    staticClass: "col-md-12 mt-2"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm.isError ? _c('b-row', {
    staticClass: "justify-content-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" Opps .. Something is wrong ")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "card-body"
  }, [_c('h3', {
    staticClass: "h3 text-center"
  }, [_vm._v("Product in Brands")]), _c('h4', {
    staticClass: "h4 text-center"
  }), _c('div', {
    staticClass: "row justify-content-end pb-1"
  }, [_c('label', {
    staticClass: "col-sm-auto",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.totalRows))])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "xl",
      "show-empty": "",
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "items": _vm.product
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(release_date)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(Date(row.item.release_date)) + " ")];
      }
    }, {
      key: "cell(printed_price)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.printed_price, row.item.printed_currency_code)) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "btn btn-sm btn-primary",
          attrs: {
            "to": {
              path: '/ebooks/upload/' + row.item.id
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-upload"
        })])];
      }
    }, {
      key: "cell(is_internal_content)",
      fn: function (row) {
        return [row.item.is_internal_content ? _c('span', {
          staticClass: "badge badge-primary"
        }, [_vm._v(" Y ")]) : _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v(" N ")])];
      }
    }])
  })], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }