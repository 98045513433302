<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <h3 class="h3 text-center">Product in Brands</h3>
          <h4 class="h4 text-center"></h4>
          <div class="row justify-content-end pb-1">
            <!-- <button class="btn btn-sm btn-dark" @click="actionGetEbooks">
              <i class="fa fa-refresh"></i>
            </button> -->
            <label for="" class="col-sm-auto">Count </label>
            <strong class="col-sm-auto">{{ totalRows }}</strong>
          </div>

          <b-table 
            sticky-header="500px"
            responsive="xl"
            show-empty
            :busy="isLoading"
            :isLoading="isLoading"
            :fields="fields"
            hover
            :items="product">
            <template #cell(no)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1)}}
            </template>
            <template #cell(release_date)="row">
              {{ Date(row.item.release_date) }}
            </template>
            <template #cell(printed_price)="row">
              {{
                formatPrice(
                  row.item.printed_price,
                  row.item.printed_currency_code)
              }}
            </template>
            <template  #cell(action)="row">
              <router-link
                :to="{ path: '/ebooks/upload/' + row.item.id }"
                class="btn btn-sm btn-primary"
              >
                <i class="fa fa-upload"></i>
              </router-link>
            </template>
            <template #cell(is_internal_content)="row">
             <span
                v-if="row.item.is_internal_content"
                class="badge badge-primary"
              >
                Y
              </span>
              <span v-else class="badge badge-success">
                N
              </span>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "products",
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      fields:[
        {
          key: 'no',
          label: 'No',
          thStyle: { width: "60px" },
          tdStyle: { width: "60px" }
        },
        { key: 'name', label: 'Item Name'},
        {
          key: 'edition_code',
          label: 'Edition Code',
          sortable: false
        },
        { key: 'release_date', label: 'Release Date'},
        {
          key: 'printed_price',
          label: 'Price',
          thClass: 'text-right',
        },
        {
          key: 'item_status',
          label: 'Status',
          thClass: 'text-capitalize',
          tdClass: 'text-capitalize',
        },
        { key: 'is_internal_content', label: 'Internal Content'},
        { key: 'action', label: 'Upload PDF/ePub'}
      ]
    };
  },
  watch: {
    currentPage: function() {
      this.actionGetEbooks();
    },
  },
  computed: {
    ...mapGetters("brands", ["product"]),
    ...mapState({
      isLoadingProduct: (state) => state.brands.isLoadingProduct,
      isError: (state) => state.brands.isErrorProduct,
      totalRows: (state) => state.brands.totalRowsProduct,
    }),
  },
  mounted() {
    this.actionGetEbooks();
  },
  methods: {
    ...mapActions("brands", ["fetchEbooks", "findProducts"]),
    ...mapActions("brands", ["searchBooks", "fetchEbookById"]),
    actionGetEbooks() {
      let payload = {
        id: this.$route.params.id,
      };
      this.findProducts(payload);
    },
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return "0";
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/table.css';

.card {
  overflow: hidden;
  height: calc(100vh - 140px) !important;
}

.card.middle > .card-body > div {
  overflow: hidden;
  height: calc(100% - 40px) !important;
}

.table-responsive-xl {
  overflow-y: auto;
  max-height: calc(100% - 88px) !important;
}

.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
